.boton-flotante {
    z-index: 2000;
    position: fixed;
    bottom: 24px;
    right: 24px;
    height: 42px;
    width: 42px;
    background-color: #24d367;
    color: #fff;
    border: none;
    padding: 5px;
    border-radius: 100%;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .boton-flotante:hover {
    background-color: #20b85a;
  }

  .icono-messenger {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  